<template>
  <b-card>
    <form @submit.prevent="validateBeforeSubmit">
      <h1>Add Project</h1>
      <p class="text-muted">Enter project information</p>
      <label for="">Project Name</label>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
        </b-input-group-prepend>
        <b-form-input type="text" v-validate="'required'" name="projectname" v-model="projectname" class="form-control" placeholder="Project Name" />
      </b-input-group>
      <i v-show="errors.has('projectname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('projectname')" class="help is-danger">The project name is required.</span>
      <label for="">Project Start Date</label>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-input-group-text><i class="fa fa-calendar"></i></b-input-group-text>
        </b-input-group-prepend>
        <!-- <b-form-input type="date" v-validate="'required'" name="p_start_date" v-model="p_start_date" class="form-control" placeholder="Project Start Date" /> -->
        <datepicker :class="{'is-danger': errors.has('p_start_date')}" class="rt-date-picker-project w-50" name="p_start_date" placeholder="Dropdown" v-validate="'required'" format="yyyy-MM-dd" v-model="p_start_date"></datepicker>
      </b-input-group>
      <i v-show="errors.has('p_start_date')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('p_start_date')" class="help is-danger">The project start date is required.</span>
      <b-button variant="success" class="mt-4" type="submit" block>Create Project</b-button>
    </form>
  </b-card>
</template>

<script>
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'ManagerAddProject',
  components: {
    Datepicker
  },
  data: function () {
    return {
      projectname: '',
      p_start_date: '',
    }
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            projectname: this.projectname,
            p_start_date: this.$moment(this.p_start_date).format("YYYY-MM-DD"),
            acc_project: 0
          })
          this.$http.post('/manager/create/project', data)
          .then(response => {
            if (response.status === 200) {
              this.$toasted.show('Project created successfully.', {type: 'success', duration: '3000'})
              this.$router.push('/manager/projects')
            }
          })
          .catch(error => {
            this.$toasted.show('Error.', {type: 'error', duration: '3000'})
          })
          return;
        }
      });
    }
  }
}
</script>

<style>
  .rt-date-picker-project input {
    padding: 0.3em 0.5em !important;
  }
</style>
