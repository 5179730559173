var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-card", [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.validateBeforeSubmit($event)
          }
        }
      },
      [
        _c("h1", [_vm._v("Add Project")]),
        _c("p", { staticClass: "text-muted" }, [
          _vm._v("Enter project information")
        ]),
        _c("label", { attrs: { for: "" } }, [_vm._v("Project Name")]),
        _c(
          "b-input-group",
          { staticClass: "mb-2" },
          [
            _c(
              "b-input-group-prepend",
              [
                _c("b-input-group-text", [
                  _c("i", { staticClass: "fa fa-user" })
                ])
              ],
              1
            ),
            _c("b-form-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                name: "projectname",
                placeholder: "Project Name"
              },
              model: {
                value: _vm.projectname,
                callback: function($$v) {
                  _vm.projectname = $$v
                },
                expression: "projectname"
              }
            })
          ],
          1
        ),
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errors.has("projectname"),
              expression: "errors.has('projectname')"
            }
          ],
          staticClass: "fa fa-exclamation-triangle"
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("projectname"),
                expression: "errors.has('projectname')"
              }
            ],
            staticClass: "help is-danger"
          },
          [_vm._v("The project name is required.")]
        ),
        _c("label", { attrs: { for: "" } }, [_vm._v("Project Start Date")]),
        _c(
          "b-input-group",
          { staticClass: "mb-2" },
          [
            _c(
              "b-input-group-prepend",
              [
                _c("b-input-group-text", [
                  _c("i", { staticClass: "fa fa-calendar" })
                ])
              ],
              1
            ),
            _c("datepicker", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "rt-date-picker-project w-50",
              class: { "is-danger": _vm.errors.has("p_start_date") },
              attrs: {
                name: "p_start_date",
                placeholder: "Dropdown",
                format: "yyyy-MM-dd"
              },
              model: {
                value: _vm.p_start_date,
                callback: function($$v) {
                  _vm.p_start_date = $$v
                },
                expression: "p_start_date"
              }
            })
          ],
          1
        ),
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errors.has("p_start_date"),
              expression: "errors.has('p_start_date')"
            }
          ],
          staticClass: "fa fa-exclamation-triangle"
        }),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.errors.has("p_start_date"),
                expression: "errors.has('p_start_date')"
              }
            ],
            staticClass: "help is-danger"
          },
          [_vm._v("The project start date is required.")]
        ),
        _c(
          "b-button",
          {
            staticClass: "mt-4",
            attrs: { variant: "success", type: "submit", block: "" }
          },
          [_vm._v("Create Project")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }